/* fonts.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

.App {
  text-align: center;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  overflow: hidden;
  font-family: "Poppins", sans-serif; /* overflow:auto; */
  /* scroll-behavior:calc(100vh - 150px) ; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.col-left {
  width: 50%;
  float: left;
}

.col-right {
  width: 50%;
  float: right;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.ant-table-row {
  animation: fadeIn 0.9s ease-out;
}
.odd-row {
  background-color: whitesmoke;
}
.ant-table-wrapper .ant-table {
  box-sizing: border-box;
  color: rgb(0 0 0 0);
  list-style: none;
  overflow-y: scroll;
  width: 100%;
  height: calc(100vh - 210px);
}
.ant-table-wrapper .ant-table-thead {
  width: max-content;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.ant-layout .ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
/* .line {
  border-left: 2px solid white; /* Adjust the thickness and color as needed 
  height: 100%;  Adjust as needed 
  margin-left: 10px;  Adjust the distance between the text and the line 
} */

/* .line {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  border-top: 2px solid white;  Adjust the thickness and color as needed 
} */
.line {
  margin-top: 5px;
  width: 7px; /* Adjust the thickness of the line */
  background-color: white; /* Adjust the color of the line */
  height: 70px; /* Adjust the height of the line */
  margin-left: 0px; /* Adjust the distance between the text and the line */
}

.ant-modal {
  color: rgba(0, 0, 0, 0.88);
  background: #ffffff;
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
  padding: 0;
  border-bottom: none;
  font-family: "Poppins";
}
.ant-form-item .ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  font-family: "Poppins";
}
.ant-input-affix-wrapper > input.ant-input,
:where(.css-dev-only-do-not-override-17seli4).ant-input-affix-wrapper
  > textarea.ant-input {
  font-size: 16px;
  outline: none;
  background: transparent;
  color: inherit;
  font-family: "Poppins";
}
.ant-input-outlined {
  font-family: "Poppins";
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-inline-end: 12px;
  font-family: "Poppins";
}
.ant-select-multiple
  .ant-select-selection-overflow
  .ant-select-selection-item-content {
  display: inline-block;
  margin-inline-end: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  font-family: "Poppins";
}
.ant-form-item .ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 12px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 12px;
  font-family: "Poppins";
}

.ant-statistic .ant-statistic-title {
  margin-bottom: 2px;
  color: rgb(0, 0, 0);
  font-size: 16px;
}
.ant-statistic .ant-statistic-content .ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
  font-size: 18px;
}

.container {
  display: grid;
  grid-template-columns: 65% 35%;
  background-color: #0a194e;

  height: 100vh;
}

.container .right {
  display: flex;
  background-color: #ffffff;
  justify-content: center;
}

.loginbutton {
  width: 60%;
  background-color: #041f81;
  height: 35px;
}
.loginbutton:hover {
  background-color: #718be9 !important;
}
.svg-signin img {
  width: 40%;
}
.form-container {
  width: 90%;
  overflow: hidden;
  margin-top: 10%;
  padding: 15px;
  font-family: "Poppins";
}
.ant-layout .ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.ant-btn > span {
  font-size: 14px;
  display: inline-block;
}
.antd-input,
.ant-input-password {
  font-family: "Poppins";
  font-size: 16px;
  width: 80%;
  padding: 10px;
}
/* :where(.css-dev-only-do-not-override-3rel02)[class^="ant-modal"] [class^="ant-modal"], :where(.css-dev-only-do-not-override-3rel02)[class*=" ant-modal"] [class^="ant-modal"], :where(.css-dev-only-do-not-override-3rel02)[class^="ant-modal"] [class*=" ant-modal"], :where(.css-dev-only-do-not-override-3rel02)[class*=" ant-modal"] [class*=" ant-modal"] {
  box-sizing: border-box;
  height: fit-content; 
} */

.ant-menu-dark {
  background: #0a194e;
}
.ant-layout-sider-children {
  background: #0a194e;
}
.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #0a194e;
}
.ant-layout .ant-layout-sider-children::-webkit-scrollbar {
  width: 10px;
}

.ant-layout .ant-layout-sider-children::-webkit-scrollbar-track {
  background: #0a194e;
}

.ant-layout .ant-layout-sider-children::-webkit-scrollbar-thumb {
  background: #0a194e;
}

.ant-layout .ant-layout-sider-children::-webkit-scrollbar-thumb:hover {
  background: #0a194e;
}
.ant-menu-dark .ant-menu-item-selected {
  background: #1242ef;
}

/* .ant-input-disabled,
.input-number-disabled,
.ant-select-disabled {
  color: black !important;
} */
.ant-input:disabled,
.ant-input-number-input:disabled,
.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input-outlined.ant-input-disabled {
  color: black;
}
/* .custom-table .ant-table-thead > tr > th {
  font-size: 10px;
} */

.custom-table .ant-table-tbody > tr > td ,.font-table-inside{
  font-size: 12px; /* Cell font size */
}
.custom-table-th .ant-table-thead > tr > th,
.custom-table-th .ant-table-column-title {
  font-size: 12px; 
}
